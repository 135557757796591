@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500&display=swap');

:root {
    --color-bg: #e2f3f5;
    --color-font: #03153a;
    --color-primary: #3d5af1;
    --color-primary-transp: #3d5bf1ea;
    --color-secundary: #22cfee;
    --color-secundary-transp: #22cfeee7;
}

::-webkit-scrollbar{
    display: none;
}

html{
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}
body {
    font-size: 62.5%;
    font-family: Helvetica, sans-serif;
    background-color: var(--color-font);
}

h1 {
    font-size: clamp(2.3em, 4vw, 5em);
}

h2 {
    font-size: clamp(2em, 2.5vw, 3em);
}

h3 {
    margin: auto;
    font-size: clamp(1.2em, 1.6vw, 2.2em);
    color: var(--color-secundary);
}

h4 {
    font-size: clamp(1.8em, 2vw, 2.7em);
}

p {
    font-size: clamp(1em, 1.5vw, 2em) ;
}

.container {
    background-color: var(--color-primary);
}

a {
    font-weight: bold;
    text-decoration: none;
    color: var(--color-font);
    transition: color 0.3s;
}
a:visited {
    color: var(--color-font);
}
a:hover {
    color: var(--color-secundary);
}
a.active {
    color: var(--color-secundary);
    background-color: var(--color-font);
}
