/* Contact Sheet */

.form-container {
    padding-top: 59px;
    display: flex;
    margin: auto;
    height: 100%;
}
form {  
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 3%;
    margin: 10vh auto;
    border-radius: 3em;
    background-color: var(--color-primary);
    box-shadow: 0px 10px 20px black;
    gap: 15px;
}
form h2 {
    font-family: "Source Code Pro", monospace;
    padding: 4% 0% 5% 6%;
}
input, textarea {
    padding: 10px;
    color: var(--color-font);
    background-color: var(--color-secundary);
    border-radius: 0.5em;
    resize: none;  
    border: solid 3px var(--color-secundary);
    transition: 0.3s;
}
input:focus, textarea:focus {
    border: solid 3px var(--color-font);
}
button {
    margin: auto;
    cursor: pointer;
    color: var(--color-fonts);
    padding: 1% 2%;
    border-radius: 0.5em;
    background-color: var(--color-secundary);
}
button:hover {
    scale: 1.03;
    background-color: var(--color-font);
    font-weight: 600;
    color: var(--color-secundary);
    transition: all 0.3s;
}