/* Navigation */

header {
    width: 100%;
    height: 8vh;
    display: flex;
    justify-content: end;
    position: fixed;
    z-index: 20;
}
nav {
    width: 100%;
    justify-content: end;
    display: flex;
    margin: auto 0;
}
nav a {
    margin: 1% 3%;
    padding: 5px 10px;
    font-size: clamp(1.5em, 3vw, 2em);
    font-weight: 600;
    border-radius: 0.7em;
}
nav a:hover {
    background-color: var(--color-secundary);
    color: var(--color-font);
    transition: 0.5s;
}